<template>
  <div class="login">
    <v-row
        align="center"
        justify="center"
        class="mt-16 pa-8"
    >
      <v-card
          class="card my-16 px-6 py-4"
          elevation="10"
      >
        <h1 class="login-subheader mb-4">{{ pageInfo.title }}</h1>
        <v-row justify="center">
          <v-col cols="12" >
            <v-form v-model="isFormValid">
            <v-text-field
                v-model="email"
                label="Email"
                :rules="this.emailRules"
                required
            ></v-text-field>
            <v-text-field
                v-model="password"
                label="Passwort"
                type="password"
                :rules="this.passRules"
                required
            ></v-text-field>
            <v-checkbox
                v-model="admin"
                v-if="pageInfo.checkbox"
                label="Ist berechtigt andere User anzulegen"
                color="success"
                hide-details
            ></v-checkbox>
            </v-form>
          </v-col>
        </v-row>
        <v-row
            align="center"
            justify="space-around"
        >
          <v-btn
              depressed
              outlined
              rounded
              x-large
              class="ma-8"
              elevation="8"
              @click="login()"
              color="primary"
              :disabled="!isFormValid"
              :loading="loader"
          >
            {{ pageInfo.btn_text }}
          </v-btn>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: Object,
    validationRules: Object,
    loader: Boolean
  },
  data: function() {
    return {
      email: '',
      password:'',
      admin: false,
      emailRules: [
        value => !!value || 'E-Mail ist erforderlich!',
        value => /^([A-Za-z0-9_üÜäÄöÖß\-.+])+@([B|burda])+\.(com)$/.test(value) || 'E-Mail muss gültig sein (@Burda.com)!',
      ],
      passRules: [
        value => !!value || 'Passwort ist erforderlich!',
        value => value.length > 5 || 'Das Passwort muss größer als 5 Zeichen sein!',
      ],
      isFormValid: false
    };
  },
  methods:{
    login: function (){
      if(this.pageInfo.checkbox){
        this.$emit('submitForm', { 'email': this.email, 'password': this.password, 'admin': this.admin})
      }
      else{
        this.$emit('submitForm', { 'email': this.email, 'password': this.password })
      }

    }
  }
}
</script>
<style scoped>
.login-subheader {
  font-size: 1.2rem;
}
.card{
  width: 500px;

}
</style>
