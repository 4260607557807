<template>
  <div class="errorMessage" :style="style">
    <v-alert
        :value="errorInfo.showError"
        dense
        outlined
        :type="errorInfo.errorType"
        dismissible
    >{{ errorInfo.message }}</v-alert>
  </div>
</template>

<script>
export default {
  props: {
    errorInfo: Object,
    width: Number
  },
  methods: {
    hide_message: function () {
      // Hide error message after 2 minutes
      const time_limit = 2 * 60 * 1000 // 2 Minutes
      window.setInterval(() => {
        this.errorInfo.showError = false;
      }, time_limit)
    },
  },
  computed: {
    style () {
      return 'width: ' + this.width + 'px';
    }
  },
  mounted () {
    this.hide_message();
  },
}
</script>
<style scoped>

.errorMessage{
  width: 500px;
  position: absolute;
  top: 90px;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
</style>
