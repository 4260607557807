<template>
    <v-btn
        text
        color="primary"
        elevation="10"
        rounded
        x-large
        @click="logout()"
        style="margin: 5px; text-transform: none;"
    >
      ausloggen
    </v-btn>
</template>
<script>
export default {
  props: {

  },
  methods: {
    logout: function (){
      // Check admin value is present in cookie.
      if(this.$cookies.get("is_admin") !== null){
        // Remove admin value from cookie.
        this.$cookies.remove("is_admin")
      }
      // Remove access token from cookie.
      this.$cookies.remove("access_token")
      // Redirect use to login page.
      this.$router.push('/')
    }
  },
  mounted () {

  },
}
</script>
