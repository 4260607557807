<template>
  <div class="login">
    <v_alert v-if="showMsg" :width='500' :error-info="errorInfo"></v_alert>
    <v_card :page-info="page_info" :loader="loader" @submitForm="login($event)"/>
  </div>
</template>

<script>
import v_card from '@/components/v_card.vue'
import v_alert from '@/components/v_alert.vue'

export default {
  name: 'Einloggen',
  components: {
    v_card,
    v_alert
  },
  data: () => ({
    page_info:{
      title: 'Einloggen',
      checkbox: false,
      btn_text: 'Einloggen'
    },
    errorInfo: {
      message: '',
      errorType: '',
      showError: true
    },
    showMsg: false,
    loader: false
  }),
  methods:{
    async login({email, password}){
      /**
       * Call api to check user details are correct or not. Store JWT token in a cookie.
       * @type {RegExp}
       */
      const emailRegex = /^([A-Za-z0-9_üÜäÄöÖß\-.+])+@([B|burda])+\.(com)$/;
      if(email !== '' && password !== '' && emailRegex.test(email) === true){
        try {
          this.loader = true
          const response = await this.$http.post('/login', {
            email: email,
            password: password
          });
          this.loader = false
          if(response.status === 200){
            // Store JWT token and user is admin or not value in a cookie.
            this.$cookies.set("access_token", response.data.token , { expires: '8h' })
            this.$cookies.set("is_admin", response.data.is_admin , { expires: '8h' })
            this.$router.push('/gen2')
          }
        }
        catch (error){
          this.loader = false
          this.displayError(error.response.data.message)
        }
      }
      else {
        this.displayError('Bitte geben Sie die User-Daten ein. Die Email muss auf @burda.com enden!')
      }
    },
    displayError(message){
      this.errorInfo.message = message
      this.errorInfo.errorType = 'error'
      this.errorInfo.showError = true
      this.showMsg = true
    },
    check_user_already_logged_in: function () {
      // Redirect user to siegel generator page if a user is already login.
      if(this.$cookies.get("access_token") !== null){
        this.$router.push('/siegel')
      }
    }
  },
  created: function () {
    document.title = 'Award Generator - Login Page'
  },
  beforeMount(){
    this.check_user_already_logged_in()
  },
}
</script>
<style scoped>
.login{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
</style>
