<template>
  <div class="siegel_generator">
    <nav_menu></nav_menu>
      <v_alert v-if="showMsg" :width='600' :error-info="errorInfo"></v_alert>
      <siegel_v_card :page-info="page_info" :loader="loader" @generateSiegel="generate_siegel($event)"> </siegel_v_card>
      <v-container v-if="showResult">
        <v-row no-gutters>
          <v-col
              md="6"
              offset-md="3">
            <v-card
                class="pa-4"
                outlined
                tile
            >
              <h2 class="green--text">Die Siegel wurden erfolgreich erstellt und sind nun zum Download bereit</h2><br>
              <v-card-actions class="justify-center">
                <a style="text-decoration: none;" :href="this.zipUrl"><v-btn rounded outlined x-large color="primary" class="mx-2">Siegel herunterladen</v-btn></a>
              </v-card-actions>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>
<script>
import v_alert from '@/components/v_alert.vue'
import nav_menu from '@/components/nav.vue'
import siegel_v_card from '@/components/siegel_v_card.vue'

export default {
  name: 'Siegel_Generieren',
  components: {
    nav_menu,
    v_alert,
    siegel_v_card
  },
  computed: {
    env() {
      return process.env;
    }
  },
  data: () => ({
    page_info:{
      title: 'Siegel generieren',
      checkbox: false,
      btn_text: 'generieren'
    },
    is_admin: false,
    navigation_info: {
      to: '/add_user',
      text: '+ User hinzufügen'
    },
    errorInfo: {
      message: '',
      errorType: '',
      showError: true
    },
    showMsg: false,
    bucket: '',
    zipUrl: '',
    showResult: false,
    loader: false,
    order_item_id: false
  }),
  methods:{
    /**
     * change frontend appearance
     */
    route_switch(order_item_id){
      // set to global because this is needed in the generate_siegel function
      this.order_item_id = order_item_id
      // change button text
      this.page_info.btn_text = 'resolve'
    },


    /**
     * Call api to generate siegel
     *
     * @param project
     * @param period
     * @param toplist_label
     * @param source
     * @param siegel_type
     * @returns {Promise<void>}
     */
    async generate_siegel({project, period, toplist_label, source, siegel_type, siegel_design}){
      try {
        const configHeaders = {
          "Authorization": `${this.$cookies.get('access_token')}`
        };
        this.loader = true
        this.showResult = false
        this.showMsg = false
        var response = null
        // if order_item_id is given, we have an open case to solve -> different route
        if (this.order_item_id){
          response = await this.$http.post('/resolve_cases', {
            order_item_id: this.order_item_id,
            project: project,
            year: period,
            toplist: toplist_label,
            source: source
            // siegel_type: siegel_type todo: not supported yet
            // selected_design: siegel_design todo: not supported yet
          },
          {
            headers: configHeaders
          });
        } else {
          response = await this.$http.post('/generate_siegel', {
            project: project,
            period: period,
            toplist_label: toplist_label,
            source: source,
            siegel_type: siegel_type,
            selected_design: siegel_design
          },
          {
            headers: configHeaders
          });
        }

        this.loader = false
        if(response.status === 200){
          // Get bucket name, zip file key from s3 bucket.
          let bucket = response.data.urls[0]
          let zipUrl = response.data.urls[1]
          this.generate_pre_signed_url(bucket, zipUrl)
          this.showResult = true
          this.hide_result()
        }
      }
      catch (error){
        this.loader = false
        this.displayError(error.response.data.message)
      }
    },
    async generate_pre_signed_url (bucket, zipUrl) {
      /**
       * Generate pre-signed URL(expires after 3 minutes) for currently create 3 files to download it file from s3 bucket.
       */

      try {
        const configHeaders = {
          "Authorization": `${this.$cookies.get('access_token')}`
        };

        var response = await this.$http.post('/get_presigned_url', {
          zipUrl: zipUrl
        },
        {
          headers: configHeaders
        });

        this.zipUrl = response.data
      } catch (err) {
        this.displayError('Die Siegel sind nicht mehr herunterladbar!')
      }
    },
    displayError(message){
      this.errorInfo.message = message
      this.errorInfo.errorType = 'error'
      this.errorInfo.showError = true
      this.showMsg = true
    },
    admin(){
      const admin = (this.$cookies.get("is_admin") === 'true')
      return admin === true

    },
    menuActionClick(action) {
      this.$router.push(action)
    },
    check_user_logged_in: function () {
      if(this.$cookies.get("access_token") === null){
        this.$router.push('/')
      }
    },
    hide_result: function () {
      // Hide result after 3 minutes of a show.
      const time_limit = 3 * 60 * 1000 // 3 Minutes
      window.setInterval(() => {
        this.showResult = false;
      }, time_limit)
    },
    check_is_admin: function (){
      const admin = (this.$cookies.get("is_admin") === 'true')
      this.is_admin =  admin === true
    },
    async validate_token(){
        // Check JWT token is valid or not.
        const response = await this.$http.post('/check_token', {
          token: this.$cookies.get('access_token')
        });
        if (response.data.status === 'Expired'){
          alert('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an!')
          this.$cookies.remove("access_token")
          if(this.$cookies.get("is_admin") !== null){
            this.$cookies.remove("is_admin")
          }
          this.$router.push('/')
        }
    },
    check_token_valid(){
      // JWT will be checked every 2 hours.
      const check_2_hours = 2 * 60 * 60 * 1000;
      window.setInterval(() => {
        if(this.$cookies.get("access_token") !== null){
          this.validate_token()
        }
      }, check_2_hours)
    }
  },
  created: function () {
    document.title = 'Award Generator - Generate Siegel'
  },
  beforeMount() {
    this.check_is_admin()
    this.check_user_logged_in()
    this.check_token_valid()
  }
}
</script>
<style scoped>
.siegel_generator{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
</style>
