<template>
  <div class="add_user">
    <nav_menu></nav_menu>
    <v_alert v-if="showMsg" :width='500' :error-info="errorInfo"></v_alert>
    <v_card :page-info="page_info" :loader="loader" @submitForm="addUser($event)"/>
  </div>
</template>
<script>
import v_card from '@/components/v_card.vue'
import v_alert from '@/components/v_alert.vue'
import nav_menu from '@/components/nav.vue'

export default {
  name: 'User hinzufügen',
  components: {
    v_card,
    v_alert,
    nav_menu
  },
  data: () => ({
    navigation_info: {
      to: '/siegel',
      text: 'Siegel generieren'
    },
    page_info:{
      title: 'User hinzufügen',
      checkbox: true,
      btn_text: 'hinzufügen'
    },
    errorInfo: {
      message: '',
      errorType: '',
      showError: true
    },
    showMsg: false,
    loader: false
  }),
  methods:{
    menuActionClick(action) {
      this.$router.push(action)
    },
    async addUser({email, password, admin}) {
      /**
       * Call api to add user details in a database.
       * @type {RegExp}
       */
      const emailRegex = /^([A-Za-z0-9_üÜäÄöÖß\-.+])+@([B|burda])+\.(com)$/;
      if(email !== '' && password !== '' && emailRegex.test(email) === true){
        try
        {
          this.loader = true
          const configHeaders = {
            "Authorization": `${this.$cookies.get('access_token')}`
          };
          const response = await this.$http.post('/add_user', {
            email: email,
            password: password,
            admin: admin
          },
          {
            headers: configHeaders
          });
          this.loader = false
          if(response.status === 201){
            this.displayError(response.data.message, 'success')
          }
        }
        catch (error){
          this.loader = false
          this.displayError(error.response.data.message, 'error')
        }
      }
      else {
        this.displayError('Bitte geben Sie die User-Daten ein. Die Email muss auf @burda.com enden!', 'error')
      }
    },
    displayError(message, errorType){
      // Show error and success message.
      this.errorInfo.message = message
      this.errorInfo.errorType = errorType
      this.errorInfo.showError = true
      this.showMsg = true
    },
    check_user_logged_in: function () {
      if(this.$cookies.get("access_token") === null){
        this.$router.push('/')
      }
    },
    check_is_admin: function (){
      const admin = (this.$cookies.get("is_admin") === 'true')
      if(!admin){
        this.$router.push('/siegel')
      }
    },
    async validate_token(){
      // Check JWT token is valid or not.
      const response = await this.$http.post('/check_token', {
        token: this.$cookies.get('access_token')
      });
      if (response.data.status === 'Expired'){
        alert('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an!')
        this.$cookies.remove("access_token")
        if(this.$cookies.get("is_admin") !== null){
          this.$cookies.remove("is_admin")
        }
        this.$router.push('/')
      }
    },
    check_token_valid(){
      // JWT will be checked every 2 hours.
      const check_2_hours = 2 * 60 * 60 *1000;
      window.setInterval(() => {
        if(this.$cookies.get("access_token") !== null){
          this.validate_token()
        }
      }, check_2_hours)
    }
  },
  created: function () {
    document.title = 'Award Generator - Add User'
  },
  beforeRouteEnter(to,from,next) {
    if (window.$cookies.get('access_token') === null) {
      next({ name: 'Login' })
    }
    else {
      next()
    }
  },
  beforeMount() {
    this.check_user_logged_in()
    this.check_is_admin()
    this.check_token_valid()
  }
}
</script>
<style scoped>
.add_user{
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
</style>
