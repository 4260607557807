<template>
  <div class="text-right">
        <v-btn
        to="/gen2"
        text
        color="primary"
        elevation="10"
        rounded
        x-large
        style="margin: 5px">
        Siegelgenerator
    </v-btn>
    <v-btn
        to="/"
        text
        color="primary"
        elevation="10"
        rounded
        x-large
        style="margin: 5px">
        Arztsuche Siegel
    </v-btn>
    <v-btn
        to="/open_cases"
        text
        color="primary"
        elevation="10"
        rounded
        x-large
        style="margin: 5px">
        Open Cases
    </v-btn>

    <v-btn
        to="/add_user"
        text
        color="primary"
        elevation="10"
        rounded
        x-large
        style="margin: 5px">
        Nutzer Hinzufügen
    </v-btn>
    <Logout></Logout>
  </div>
</template>
<script>
import Logout from '@/components/logout.vue'
export default {
  name: 'test',
  components: {
    Logout
  }
}
</script>