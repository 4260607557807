import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueCookie from 'vue-cookies'
import { environment, dev_endpoint, pro_endpoint, stage_endpoint } from '../config.json'

const configHeaders = {
  "content-type": "application/json",
  "Accept": "application/json"
};

// Configure BASE_URL as per environment.
let BASE_URL = dev_endpoint
if (environment === 'Production')
{
  BASE_URL = pro_endpoint
} else if (environment === 'Stage')
{
  BASE_URL = stage_endpoint
}

/**
 * Axios HTTP: $http
 */
Vue.prototype.$http = axios.create({
  baseURL: BASE_URL,
  timeout: 120000, // 120 seconds
  headers: configHeaders
})

Vue.use(VueCookie);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
