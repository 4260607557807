<template>
  <div class="open_cases">
    <nav_menu></nav_menu>
    <v-container>
        <h1 class="my-3">All open cases</h1>
        <v-col cols="6">
            <v-select
              v-model="dropdown_select"
              :items="dropdown_items"
              label="Filter by Status"
            ></v-select>
            <v-text-field
                v-model="search_value"
                label="Or search by ID"
            ></v-text-field>
            <v-btn @click.native="getCases()">
                Submit
            </v-btn>
        </v-col>
        <v-simple-table>
        <thead>
        <tr>
          <th class="text-left column-width">created_on</th>
          <th class="text-left column-width">period</th>
          <th class="text-left column-width">project</th>
          <th class="text-left column-width">toplist_label</th>
          <th class="text-left column-width">ORDER_ITEM_ID</th>
          <th class="text-left column-width">edit</th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="item in cases" :key="item.id">
              <td>
                {{ item.created_on }}
              </td>
              <td>
                {{ item.period }}
              </td>
              <td>
                {{ item.project }}
              </td>
              <td>
                {{ item.toplist_label }}
              </td>
              <td>
                {{ item.order_item_id }}
              </td>
              <td class="edit">
                <v-btn :href="'/#/siegel?order_item_id=' + item.order_item_id">Edit</v-btn>
              </td>
            </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          v-model="page_current"
          :length="page_total"
          @input="getPage"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import nav_menu from '@/components/nav.vue'

export default {
  name: 'Shop open cases',
  components: {
    nav_menu
  },
  data: () => ({
    cases: null,
    navigation_info: {
      to: '/siegel',
      text: 'Siegel generieren'
    },
    page_info:{
      title: 'Show all open cases'
    },
    search_value: "",
    dropdown_select: "paused",
    dropdown_items: ['paused', 'pending', 'finished'],
    page_current: 1,
    page_total: 3
  }),
  methods:{
    menuActionClick(action) {
      this.$router.push(action)
    },

    async getOpenCases() {
      /**
       * get all data filled in the form, build an url and call the backend api
       * parse and append the returned list
       */

      var url = '/open_cases'
      url += '?filter=' + this.dropdown_select

      if (this.search_value.length > 0){
        url += '&search=' + this.search_value
      }

      url += '&page=' + (this.page_current - 1)

      const response = await this.$http.get(url);

      var data = response.request.response
      this.cases = JSON.parse(data).cases
      this.page_total = JSON.parse(data).total_pages
    },

    getCases(){
       /**
       * simple wrapper to call on button click
       */
       this.page_current = 1
       this.getOpenCases()
    },

    getPage(page) {
       /**
       * called on pagination click.
       */
      this.page_current = page
      this.getOpenCases()
    },

    check_user_logged_in: function () {
      if(this.$cookies.get("access_token") === null){
        this.$router.push('/')
      }
    },
    async validate_token(){
      // Check JWT token is valid or not.
      const response = await this.$http.post('/check_token', {
        token: this.$cookies.get('access_token')
      });
      if (response.data.status === 'Expired'){
        alert('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an!')
        this.$cookies.remove("access_token")
        if(this.$cookies.get("is_admin") !== null){
          this.$cookies.remove("is_admin")
        }
        this.$router.push('/')
      }
    },
    check_token_valid(){
      // JWT will be checked every 2 hours.
      const check_2_hours = 2 * 60 * 60 *1000;
      window.setInterval(() => {
        if(this.$cookies.get("access_token") !== null){
          this.validate_token()
        }
      }, check_2_hours)
    }
  },
  created: function () {
    document.title = 'Award Generator - All Open Cases'
    this.getOpenCases()
  },
  beforeRouteEnter(to,from,next) {
    if (window.$cookies.get('access_token') === null) {
      next({ name: 'Login' })
    }
    else {
      next()
    }
  },
  beforeMount() {
    this.check_user_logged_in()
    this.check_token_valid()
  }
}
</script>
<style scoped>

</style>
