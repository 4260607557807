<template>
  <div>
    <v-row
        align="center"
        justify="center"
        class="mt-16 pa-8"
    >
      <v-card
          class="card my-16 px-6 py-4"
          elevation="10"
      >
        <v-form v-model="isFormValid">
        <h1 class="login-subheader mb-4">{{ pageInfo.title }}</h1>
        <v-row align="center" justify="center">
          <v-col cols="6">
            <v-autocomplete
                v-model="project"
                :items="project_items"
                label="Projekt"
                required
                :rules="requiredRule"
                @change="checkProject(project)"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
                v-model="period"
                :items="period_items"
                label="Jahrgang"
                required
                :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="toplistColClass">
            <v-textarea
                label="Toplisten-Spezifikation (Fachbereich, Branche, Schwerpunkt...)"
                v-model.trim="toplist_label"
                auto-grow
                outlined
                rows="3"
                row-height="20"
                @input="(val) => (toplist_label = toplist_label.toUpperCase())"
                :disabled="project === 'Wch'"
                ref="toplisten"
                class="toplistClass"
            ></v-textarea>
            <v-btn
                depressed
                outlined
                rounded
                small
                elevation="8"
                @click="addSpace()"
                color="primary"
                :disabled="!this.toplist_label.length > 0"
                class="toplistBtnClass"
            >
              Add Unsplittable Space
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-text-field
                v-model.trim="source"
                :label="this.sourceFieldLabel"
                :rules="requiredRule"
                @input="(val) => (source = source.toUpperCase())"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
                v-model="selected_design"
                :items="designs"
                :item-text="item => `${item.text} ${item.value}`"
                label="Siegel-Design wählen"
                required
                :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-radio-group
                v-model="siegel_type"
                row
                v-if="typeOfView"
            >
              <v-radio
                  label="Vertikal (hoch)"
                  value="vertical"
              ></v-radio>
              <v-radio
                  label="Horizontal (quer)"
                  value="horizontal"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4">
          <v-btn
              depressed
              outlined
              rounded
              x-large
              elevation="8"
              @click="generate()"
              color="primary"
              :disabled="!isFormValid"
              :loading="loader"
          >
            {{ pageInfo.btn_text }}
          </v-btn>
          </v-col>
        </v-row>
        </v-form>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    pageInfo: Object,
    loader: Boolean
  },
  data: function() {
    return {
      project: '',
      period:'',
      toplist_label:'',
      source:'',
      requiredRule: [
        value => !!value || 'Dieses Feld ist erforderlich!',
        value => /^([A-Za-z0-9_üÜäÄöÖß&() /\-.+])+$/.test(value) || 'Wert akzeptiert nur alphanumerische Zeichen!',
      ],
      isFormValid: false,
      project_items: [],
      period_items: [],
      siegel_type: 'vertical',
      typeOfView: true,
      designs: [
        {
          text: 'aktuell: Siegel-Design ab dem Jahr',
          value: '2021'
        },
        {
          text: 'Siegel-Design des Jahres',
          value: '2020'
        },
        {
          text: 'Siegel-Design von 2016 bis',
          value: '2019'
        }
      ],
      selected_design: '2021',
      sourceFieldLabel: 'Quelle'
    };
  },
  methods:{
    /**
     * prefill form with siegel data, if order_item_id is given
     */
    async prefill_form(){
      // check if there is a parameter
      var order_item_id = this.$route.query.order_item_id
      if (typeof order_item_id == 'undefined'){
        return
      }

      // get all open cases and parse to json
      const response = await this.$http.get('/open_cases?search=' + order_item_id);
      var cases = response.request.response
      cases = JSON.parse(cases).cases

      // find case with matching order_item_id
      var case_obj = cases.find(case_obj => case_obj.order_item_id === order_item_id)
      // if case not found
      if (typeof case_obj == 'undefined'){
        return
      }

      // fill form
      this.project = case_obj['project']
      this.period = parseInt(case_obj['period'])
      this.toplist_label = case_obj['toplist_label']
      this.source = case_obj['source']

      // because we have a already existing job in the database the route have to be a different to solve the issue
      this.$parent.route_switch(case_obj['order_item_id'])
    },
    generate: function (){
      // WCH Project has vertical view without toplist.
      if(this.project === 'Wch'){
        this.toplist_label = ''
        this.siegel_type = 'vertical'
      }
      // Arztsuche project has vertical view only.
      if (this.project === 'Arztsuche'){
        this.siegel_type = 'vertical'
      }
      this.$emit('generateSiegel', { 'project': this.project, 'period': String(this.period), 'toplist_label': this.toplist_label, 'source': this.source, 'siegel_type': this.siegel_type, 'siegel_design': this.selected_design})
    },
    async siegel_config(){
      try {
        const configHeaders = {
          "Authorization": `${this.$cookies.get('access_token')}`
        };
        const response = await this.$http.get('/get_siegel_config',
        {
          headers: configHeaders
        });
        if(response.status === 200){
          const result = JSON.parse(JSON.stringify(response.data))
          // Show period and project values in dropdown from a backend.
          this.period_items = result.period
          this.project_items = result.project
        }
      }
      catch (error){
        this.$cookies.remove("access_token")
        if(this.$cookies.get("is_admin") !== null){
          this.$cookies.remove("is_admin")
        }
        this.$router.push('/')
      }
    },
    checkProject: function (project_name) {
      if(project_name === 'Wch' || project_name === 'Arztsuche'){
        this.typeOfView = false
      }
      else{
        this.typeOfView = true
      }
      if(project_name === 'Arztsuche'){
        this.sourceFieldLabel = 'Landkreis'
      }
      else{
        this.sourceFieldLabel = 'Quelle'
      }
    },
    addSpace: function () {
      const textarea = this.$refs.toplisten.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart

      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.toplist_label = before + '..' + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + 1
      })
    }
  },
  created() {
    this.siegel_config()
    this.prefill_form()
  }
}
</script>
<style scoped>
.login-subheader {
  font-size: 1.2rem;
}
.card{
  width: 600px;
}
.toplistColClass{
  display:inline-block;
  position:relative;
}

.toplistBtnClass{
  position:absolute;
  bottom:10px;
  right:10px;
}

.toplistClass{
  display:block;
}
</style>
