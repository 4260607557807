import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Siegel from '../views/generate_siegel.vue'
import AddUser from '../views/add_user.vue'
import OpenCases from '../views/open_cases.vue'
import Gen2 from '../views/gen2.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/siegel',
    name: 'Siegel',
    component: Siegel
  },
  {
    path: '/add_user',
    name: 'Add_User',
    component: AddUser
  },
   {
    path: '/open_cases',
    name: 'Open_cases',
    component: OpenCases
  },
  {
    path: '/gen2',
    name: 'Generator2',
    component: Gen2
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
