<template>
  <div class="gen2">
    <nav_menu></nav_menu>
      <v-row
          align="center"
          justify="center"
      >
        <v-card
            class="card my-16 px-6 py-4"
            elevation="10"
            height="30%"
            width="30%">
          <v-form>
            <h1 class="login-subheader mb-4">{{ this.page_info.title }}</h1>
            <v-autocomplete
                  v-model="project"
                  :items="project_items"
                  label="Projekt"
                  required
                  @change="get_project_config(project)"
              ></v-autocomplete>

            <v-dynamic-form v-model="form" :input-fields="inputs" @submit="submit_form(true)"/>
          </v-form>
        </v-card>
      </v-row>
      <v-row
            align="center"
            justify="center">
        <v-alert
          outlined
          type="warning"
          prominent
          border="left"
          v-if="alert.state"
        >{{alert.message}}
        </v-alert>
      </v-row>
      <v-row
          align="center"
          justify="center"
          id="preview_card"
          :class="{ 'd-none': preview_is_hidden }"
      >
        <v-card class="card my-16 px-6 py-4" elevation="10"
                height="30%"
                width="30%">
          <h1 class="login-subheader mb-4">Award Preview</h1>
          <v-img
              contain
            id="image"
            :src="preview_award"
            aspect-ratio="1"
            class="grey lighten-2"
          />
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click.prevent="submit_form(false)"
            :href="download_url"
          >
            Download
            <v-icon
              right
              dark
            >
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </v-card>
      </v-row>
  </div>
</template>
<script>

import nav_menu from '@/components/nav.vue'
import Vue from 'vue'
import VDynamicForm from '@moirei/vuetify-dynamic-form'

Vue.use(VDynamicForm)

export default {
  name: 'Generate Award',
  components: {
    nav_menu
  },
  data: () => ({
    project_items: [],
    project: "",
    preview_award: "",
    preview_is_hidden: true,
    alert: {
      state: false,
      message: ""
    },
    download_url: "",
    form: {},
    inputs: {
    },
    award_data: [
      {
        component: "foo2",
        title: "toplist"
      },
      {
        component: "foo2",
        title: "year"
      }
    ],
    page_info:{
      title: 'Generate Award'
    },
  }),
  methods:{
    async get_project_config(projectname){

      let url = '/gen2_interface';
      url += '?project=' + projectname;

      const response = await this.$http.get(url);

      if(response.status !== 200) {
        console.log("get_project_config: There was an error requesting the project config");
        return;
      }

      const project_config = JSON.parse(JSON.stringify(response.data));

      let loc_inputs = {};

      for (let j = 0; j < project_config.length; j++){

        let inputtype = project_config[j]["ui_config"]["inputtype"];
        let element_name = project_config[j]["name"];

        switch(inputtype){
          case "text":
            loc_inputs[element_name] = {
              name: element_name,
              type: "text",
              line: 1,
              props: {
                filled: true,
              }
            }
            break;
          case "dropdown":
            loc_inputs[element_name] = {
              name: element_name,
              type: "select",
              line: 1,
              props: {
                filled: true,
                items: project_config[j]["ui_config"]["items"]
              }
            }
            break;
          case "textarea":
            loc_inputs[element_name] = {
              name: element_name,
              type: "textarea",
              line: 1,
              props: {
                filled: true,
                noResize: true,
                rows: project_config[j]["ui_config"]["lines"]
              }
            }
            break;
          case "graphic_dropdown":
            loc_inputs[element_name] = {
              name: element_name,
              type: "select",
              line: 1,
              props: {
                filled: true,
                items: project_config[j]["ui_config"]["items"]
              }
            }
            break;
        }
        // add new element types here
        // add a label if defined. This only works for some input element types
        const label = project_config[j]["ui_config"]["label"]

        if (label){
          loc_inputs[element_name]["props"]["label"] = label;
        }

        const value = project_config[j]["ui_config"]["value"]
        console.log(value)

        if (value){
          this.form[element_name] = value
        }

      }
      this.inputs = loc_inputs;
    },
    async submit_form(preview) {
      const preview_url = '/gen2_interface_generate_preview';
      const zip_url = '/gen2_interface_generate_zip';
      let message = ""

      if (preview) {
        // here we receive a file as attachment, therefore we need to call with response type blob
        const response = await this.$http.post(preview_url, {"project": this.project, "form_data": this.form, "preview": preview}, {responseType: "blob"});

        if (response.status !== 200){
          message = "submit_form: Error while generating a preview";
          this.show_alert(message);
          console.log(message);
          return;
        }
        await this.show_preview_award(response.data);
        this.download_url = "";

        return;
      }
        // Check if the download url is already there, if not, generate it
        if (this.download_url === ""){
          const response = await this.$http.post(zip_url, {"project": this.project, "form_data": this.form});
          if (response.status !== 200){
            message = "submit_form: Error while generating a zip file";
            this.show_alert(message)
            console.log(message);
            }
          this.download_url = response.data;
        }
        // always trigger the download prompt
        const link = document.createElement('a')
        link.href = this.download_url;
        link.click();
    },
    async fill_projects() {
      // fill project dropdown with list of all projects
      let url = '/gen2_interface'
      url += '?project=all'

      const response = await this.$http.get(url);

      if(response.status === 200){
        this.project_items = JSON.parse(JSON.stringify(response.data))
      }
    },
    async show_preview_award(image){
      this.preview_award = window.URL.createObjectURL(image);

      // make card visible
      this.preview_is_hidden = false
    },
    show_alert(message){
      this.alert["state"] = true
      this.alert["message"] = message
      setTimeout(()=>{this.alert["state"]=false},10000)
    },

    check_user_logged_in: function () {
      if(this.$cookies.get("access_token") === null){
        this.$router.push('/')
      }
    },
    async validate_token(){
      // Check JWT token is valid or not.
      const response = await this.$http.post('/check_token', {
        token: this.$cookies.get('access_token')
      });
      if (response.data.status === 'Expired'){
        alert('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an!')
        this.$cookies.remove("access_token")
        if(this.$cookies.get("is_admin") !== null){
          this.$cookies.remove("is_admin")
        }
        this.$router.push('/')
      }
    },
    check_token_valid(){
      // JWT will be checked every 2 hours.
      const check_2_hours = 2 * 60 * 60 *1000;
      window.setInterval(() => {
        if(this.$cookies.get("access_token") !== null){
          this.validate_token()
        }
      }, check_2_hours)
    }
  },
  created: function () {
    document.title = 'Award Generator - Generate Award'
  },
  beforeRouteEnter(to,from,next) {
    if (window.$cookies.get('access_token') === null) {
      next({ name: 'Login' })
    }
    else {
      next()
    }
  },
  beforeMount() {
    this.check_user_logged_in()
    this.check_token_valid()
    this.fill_projects()
  }
}
</script>